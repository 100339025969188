
.hire_icons { overflow: hidden; }
.hire_icons .icons_section { margin-top: -30px; }
.hire_icons .icons_section > img { width: 85px; height: 85px; }
.avatar_hire:hover { transform: translateY(-1.5rem); }



@media (max-width: 1100px) {
    .hire_icons .icons_section > img { width: 55px; height: 55px; }
}

@media (max-width: 800px) {
    .hire_icons .icons_section > img { width: 55px; height: 55px; }
    .grid_hire .g-col-2 { grid-template-columns: repeat(1 , 1fr); }
    .hire_icons .icons_section { margin-top: -40px; }
}

@media (max-width: 600px) {
    .hire_icons .icons_section > img { width: 40px; height: 40px; }
}

@media (max-width: 400px) {
    .hire_icons .icons_section > img { width: 30px; height: 30px; }
    .hire_icons .icons_section { margin-top: -40px; }
}

@media (max-width: 300px) {
    .hire_icons .icons_section { display: block; }
    .hire_icons .icons_section > img { width: 35px; height: 35px; }
    .hire_icons .icons_section > img:last-child { margin-top: -30px; }
    .hire_icons .icons_section { margin-top: -40px; }
}