
.bordered_circular {
    display: block;
    width: 50px;
    height: 50px;
    background: #fff;
    border-radius: 50%;

}

.bordered_line {
    margin: 0 0.5rem;
    width: 120px;
    height: 5px;
    background: #fff;
    display: block;
}

.flex_done_end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0.5rem;
}

.slidings_divs {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
}



@media (max-width: 500px) {

    .bordered_circular {
       
        width: 30px;
        height: 30px;
   
    
    }
    
    .bordered_line {
        margin: 0 0.2rem;
        width: 80px;
        height: 3px;
    }

}