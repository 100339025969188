
.top_header_regsyr h1 { position: fixed; top: 5%; left: 5%; z-index: 9999; color: white; font-family: 'Pacifico'; font-weight: bold; }
.top_header_regsyr .MuiSvgIcon-root {position: fixed; top: 5%; z-index: 9999; color: white; right: 5% !important; width: 25px; height: 25px;  cursor: pointer; }
.avatar_regsyr {  max-height: 15rem; transition: all 0.4s linear;  }
.avatar_regsyr:hover {border: 5px solid transparent;
	border-radius: 500px;
   
	background-clip: padding-box;
	padding: 5px;
	box-shadow: 0 3px 9px black, inset 0 0 9px white; }
   .main_card .live_preview { position: absolute; max-height: 3rem; top: 2%; right: 10%; border: 5px solid #fff; border-radius: 50%; background: #fff; transition: 0.5s ease-in all;  }
   .main_card .live_preview:hover { border: 5px solid transparent;
	border-radius: 500px;
   
	background-clip: padding-box;
	padding: 5px;
	box-shadow: 0 3px 9px black, inset 0 0 9px white;}
.avatar_regsyr_small {  max-height: 12rem;  }
.headline_regsyr { font-size: 56px;  font-weight: 900; color: white;   }
.subheadline_regsyr { font-size: 38px; font-family: 'Gemunu Libre'; font-weight: 300; color: white; margin-top: 5px; }
.subheadline_regsyr_small { font-size: 30px; font-family: 'Gemunu Libre'; font-weight: 300; color: white; }
.menu_mobile_show { background: linear-gradient(180deg, rgb(0 ,0 ,0 , 0.9) 0%, rgb(255, 87, 236 , 0.8) 100%); width: 100%; height: 100%; position: fixed; top: 50%; left: -95%; transform: translate(-50% , -50%);  z-index: 9999; transition: 0.4s all ease-in; overflow-y: scroll; padding-bottom: 3.5rem;  }
.menu_mobile_show.active { left: 50%; }
.menu_mobile_show div .headline_regsyr { font-size: 38px; }
.menu_mobile_show div .subheadline_regsyr { font-size: 28px; }
.menu_mobile li { margin: 0.4rem 0;}
.menu_mobile li a{  color: white; font-size: 20px; border: 2px solid #fff; width: 350px; height: 38px; text-align: center; display: block; transition: all 0.6s 0.1s linear; }
.closing_div { position: fixed; top: 18%; right: 35%; }
.closing_div .MuiSvgIcon-root { color: white; width: 35px; height: 35px; cursor: pointer; }
.menu_mobile li a:hover { background: linear-gradient(#fff , #f9f9f9); color: #010101; box-shadow: -2px 0px 44px 0px #000000
; }

.icons_section > .MuiSvgIcon-root , .icons_section > img {   border: 5px solid;
	border-image-slice: 1;
	border-width: 3px; padding: 8px; color: white; width: 55px; height: 55px; margin: 1.8rem 1.2rem;   border-radius: 500px; cursor: pointer; }
	.icons_section > .MuiSvgIcon-root:hover , .icons_section > img:hover {  position: relative;
		border: 4px solid transparent;
		border-radius: 500px;
		background: linear-gradient(orange, violet);
		background-clip: padding-box;
		padding: 10px;
		/* just to show box-shadow still works fine */
		box-shadow: 0 3px 9px black, inset 0 0 9px white; }
		.hover-1 {
			--h: 1.2em; /* the height */
		  
			line-height: var(--h);
			color: #fff;
			text-shadow: 0 calc(-1 * var(--_t, 0em)) #000,
			  0 calc(var(--h) - var(--_t, 0em)) #c1104e;
			overflow: hidden;
			transition: 0.3s;
		  }

		
		  .hover-1:hover {
			--_t: var(--h);
		  }
		  
		  .hover-2 {

			overflow: hidden;
			text-shadow: 0 var(--_t, var(--h)) #fff, 0 0 var(--_c, #000);
			background: linear-gradient(#3d1848  , #2f1625  ) bottom/100% var(--_d, 0) no-repeat;
			transition: 0.3s;
		  }
		  .hover-2:hover {
			--_d: 100%;
			--_t: 0;
			--_c: #0000;
		  }






@keyframes rotate{
    from{ transform: rotate(-360deg); }
    to{ transform: rotate(360deg); }
}

@property --angle {
  syntax: '<angle>';
  initial-value: 90deg;
  inherits: true;
}

@property --gradX {
  syntax: '<percentage>';
  initial-value: 50%;
  inherits: true;
}

@property --gradY {
  syntax: '<percentage>';
  initial-value: 0%;
  inherits: true;
}





:root {
	--d: 2500ms;
	--angle: 90deg;
	--gradX: 100%;
	--gradY: 50%;
	--c1: rgb(203, 15, 84);
	--c2: rgba(168, 239, 255, 0.1);
}


.box {
	font-size: 3vw;
	margin-bottom: 0.8rem;
	border: 0.45rem solid;
   
	border-image: conic-gradient(from var(--angle), var(--c2), var(--c1) 0.1turn, var(--c1) 0.15turn, var(--c2) 0.25turn) 30;
	animation: borderRotate var(--d) linear infinite forwards;
}

.box:nth-child(2) {
	border-image: radial-gradient(ellipse at var(--gradX) var(--gradY), var(--c1), var(--c1) 10%, var(--c2) 40%) 30;
	animation: borderRadial var(--d) linear infinite forwards;
}

@keyframes borderRotate {
	100% {
		--angle: 420deg;
	}
}

@keyframes borderRadial {
	20% {
		--gradX: 100%;
		--gradY: 50%;
	}
	40% {
		--gradX: 100%;
		--gradY: 100%;
	}
	60% {
		--gradX: 50%;
		--gradY: 100%;
	}
	80% {
		--gradX: 0%;
		--gradY: 50%;
	}
	100% {
		--gradX: 50%;
		--gradY: 0%;
	}
}



@media (max-width: 1050px) {

	.menu_mobile li { margin: 0.2rem 0; }

	.headline_regsyr { font-size: 36px; }
	.subheadline_regsyr { font-size: 26px; }
	.icons_section > .MuiSvgIcon-root , .icons_section > img {   padding: 5px; width: 45px; height: 45px;  }
}

@media (max-width: 800px ) {
	.closing_div  { top: 18%; right: 20%;  }
	.avatar_regsyr { max-height: 16rem ; }
	.headline_regsyr { font-size: 32px; }
	.subheadline_regsyr { font-size: 26px; }
	.top_header_regsyr h1 { font-size: 18px;}

}

@media (max-width: 600px ) {
	.menu_mobile_show  { display: flex; flex-direction: column; align-items: center; justify-content: center; height: 100%; }
	.menu_mobile { display: block !important; }
	.menu_mobile li a {  font-size: 15px; width: 250px }
	.avatar_regsyr { max-height: 14rem ; }
	.headline_regsyr { font-size: 30px; }
	.subheadline_regsyr { font-size: 20px; }

	.top_header_regsyr h1 { font-size: 16px;}
	.top_header_regsyr .MuiSvgIcon-root  { width: 20px; height: 20px; }
}

@media (max-width: 450px ) {
	.mobile_header .headline_regsyr { font-size: 22px !important; }
	.mobile_header .subheadline_regsyr { font-size: 15px !important; }
	.mobile_header img { max-height: 6rem; }
	.menu_mobile li a {  font-size: 13px; width: 180px }
	.middle_regsyr .icons_section { display: block !important;  }
	
	.avatar_regsyr { max-height: 11rem ; }
	.headline_regsyr { font-size: 22px; }
	.subheadline_regsyr { font-size: 14px; }
	
	.icons_section > .MuiSvgIcon-root , .icons_section > img {   padding: 5px; width: 45px; height: 45px;  }
	.top_header_regsyr h1 { font-size: 14px;}
	.top_header_regsyr .MuiSvgIcon-root  { width: 25px; height: 25px; }
}


