.avatar_regsyr_big { max-height: 20rem; transition: all 0.4s linear; }
.avatar_regsyr_horzietal { 
    max-height: 25rem;

 }
.about_picture .avatar_regsyr_big:hover { transform: rotate(45deg); transform: translate(-0.5rem ,2rem) rotate(45deg); }

.about_me_btn { padding: 1.2rem 3.5rem; border: 3px solid #fff; font-size: 34px;  background: none; outline: none;  color: white; border-radius: 15px; font-family: 'Josefin Sans'; text-align: center; transition: all 0.3s ease-in; }

.about_me_btn_small { padding: 0.6rem 1.8rem; border: 3px solid #fff; font-size: 18px;  background: none; outline: none;  color: white; border-radius: 15px; font-family: 'Josefin Sans'; text-align: center; transition: all 0.3s ease-in; }


.lead_para { font-size: 18px; line-height: 28px; color: #fff; font-family: 'Quicksand' , sans-serif; }

.about_info_headline { font-family: 'Josefin Sans' ; font-weight: bolder; font-size: 22px; color: #ff1265; margin-bottom: 8px; }
.about_info_subheadline { font-family: 'Quicksand' ; font-weight: 300; font-size: 18px; color: #fff; }
.about_me_btn:hover { transform: translateY(-0.4rem); box-shadow: -2px 0px 44px 0px #000000 ;  } 
.about_me_btn_small:hover { transform: translateY(-0.4rem); box-shadow: -2px 0px 44px 0px #000000 ;  } 


@media (max-width: 1000px) {
    .avatar_regsyr_big { max-height: 16rem;  }
    .avatar_regsyr_horzietal {
        max-height: 22rem;
    }
    .about_me_btn { font-size: 26px; }
    .lead_para { font-size: 15px; line-height: 20px; }
    .about_info_headline { font-size: 18px; }
    .about_info_subheadline { font-size: 15px; }
   
}


@media (max-width: 800px) {
    .avatar_regsyr_horzietal {
        
    }
    .about_me_btn { font-size: 20px; padding: 0.8rem 1.5rem; }
    .avatar_regsyr_big { width: 100% !important; height: auto !important; } 
    .grid_about .g-col-2 { grid-template-columns: repeat(1 , 1fr); }
    .lead_para { font-size: 13px;  }
    .about_info_headline { font-size: 14px; }
    .icon_about_outlined {  width: 25px; height: 25px; }
}

@media (max-width: 600px) {
    .about_me_btn { font-size: 14px; padding: 0.3rem 0.5rem; }
    .avatar_regsyr_big {  } 
    .grid_about .g-col-2 { grid-template-columns: repeat(1 , 1fr); }
    .lead_para { font-size: 12px; line-height: 16px; }
    .about_info_headline { font-size: 12px; }
    .icon_about_outlined {  width: 20px; height: 20px; }
    .about_info_subheadline { font-size: 13px; }
}

@media (max-width: 450px) {
    .avatar_regsyr_horzietal {
        max-height: 16rem;
    }
    .lead_para { line-height: 16px; }
    .about_info_subheadline { font-size: 12px; }
}

@media (max-width: 400px) {
    .avatar_regsyr_big {  } 
}