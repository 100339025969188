
.form_contact { position: relative; margin-top: 1.5rem; }
.input_contact {   position: relative; /* Helps to keep label top on input */
    border-radius: 0.4rem;
    margin-top: 2rem;
    background-color: #e8e5e588; /* Match the background */
    border: #c1104e 0.1rem solid;
    width: 20rem;
    height: 2rem;
    padding: 0rem 0.8rem;
    font-size: 1.4rem;
    color: white;
    font-family: 'Quicksand', sans-serif;
}
.textarea_input { height: 5.5rem ; resize: none; padding: 0.4rem; font-size: 1.2rem; }
.input_contact::placeholder , .input_contact:hover , .input_contact:focus { color: white; background: transparent; }

.input_label { position: absolute; top: -8%; left: 25%; font-size: 24px; font-family: 'Gemunu Libre', sans-serif; font-weight: 100; color: white; }

.about_me_btn_small { cursor: pointer; }
.avatar_contact:hover { 
    border: 5px solid transparent;
	border-radius: 500px;
   
	background-clip: padding-box;
	padding: 5px;
	box-shadow: 0 3px 9px black, inset 0 0 9px white; }
   .main_card .live_preview { position: absolute; max-height: 3rem; top: 2%; right: 10%; border: 5px solid #fff; border-radius: 50%; background: #fff; transition: 0.5s ease-in all;  }
   .main_card .live_preview:hover { border: 5px solid transparent;
	border-radius: 500px;
   
	background-clip: padding-box;
	padding: 5px;
	box-shadow: 0 3px 9px black, inset 0 0 9px white;
}

.alert_sending {  width: 55%; height: 55%; z-index: 9999 !important; position: fixed !important; top: 50%; left: 50%; transform: translate(-50% , -50%);   padding: 1.5rem 3.5rem;  z-index: 12; background: #222; height: auto;  }

.loading_spinner { max-height: 4.5rem; }

@media (max-width: 800px ) {
    .input_label { font-size: 20px; }
    .input_contact { height: 1.5rem; }
    .textarea_input { height: 3rem; }
   .form_contact .about_me_btn_small  { padding: 0.3rem 1.2rem; font-size: 12px; }
}

@media  (max-width: 700px ) {
    .input_label { font-size: 18px; }
    .grid_contact .g-col-2 { grid-template-columns: repeat(1 , 1fr); }
   .grid_contact .grid__one .avatar_regsyr_big { display: none; }
}

@media (max-width: 400px) {
   
    .input_contact  { width: 80%; }
}