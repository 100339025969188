

.experince_flex {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.experince_avatar {
    max-height: 15rem;
}

@media (max-width : 400px) {
    .experince_avatar {
        max-height: none;
        width: 100%;
    }
}

@media (max-width : 800px) {
  .experince_section  .icons_section > .MuiSvgIcon-root , .experince_section .icons_section > img {   padding: 5px; width: 40px; height: 40px; margin: 1rem !important; }
}

@media (max-width : 600px) {
   .experince_section .icons_section > .MuiSvgIcon-root , .experince_section .icons_section > img {   padding: 5px; width: 35px; height: 35px;margin: 0.5rem !important;  }
}