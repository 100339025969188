
.single-service {  position: relative; border-radius: 20px;   padding: 1.5rem 3.5rem;  z-index: 12; background: linear-gradient( #c1104e62  , #3d1b1b8a  ) bottom/100%  no-repeat; margin: 0 1.5rem; cursor: pointer; min-height: 15rem; }

.service_icon { max-height: 7.5rem; margin-bottom: 18px; }
.popup_service_single {  width: 55%; height: 55%; z-index: 9999 !important; position: fixed !important; top: 50%; left: 50%; transform: translate(-50% , -50%);   padding: 1.5rem 3.5rem;  z-index: 12; background: linear-gradient( #7e0b33e2  , #3d1b1bd5  ) bottom/100%  no-repeat; height: auto; }

.popup_service_single .MuiSvgIcon-root { position: absolute; top: 8%; right: 5%;  color: white; width: 35px; height: 35px; cursor: pointer; }



@media (max-width: 1000px) {

    .single-service  { min-height: 12rem;  padding: 0.8rem 2.2rem; }
    .service_icon { max-height: 5.5rem; }
    .subheadline_regsyr_small { font-size: 24px; }
}

@media (max-width: 800px) {
    .popup_service_single { width: 65%;  }
    .single-service  { min-height: 10rem;  padding: 0.8rem 1.5rem; }
    .service_icon { max-height: 4.5rem; }
    .subheadline_regsyr_small { font-size: 22px; }
}

@media (max-width: 600px) {
    .popup_service_single { width: 70%;  }
    .single-service  { min-height: 10rem;  padding: 0.4rem 0.8rem; }
    .service_icon { max-height: 3.5rem; }
    .subheadline_regsyr_small { font-size: 22px; }
}

@media (max-width: 400px) {
    .single-service  { min-height: 8rem;  padding: 0.2rem 0.8rem; }
    .service_icon { max-height: 2.5rem; }
    .subheadline_regsyr_small { font-size: 18px; }
}