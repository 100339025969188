.main_card { position: relative; padding: 0 1.5rem ;}
.project_single_card {   
    border-radius: 10px;
    box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px rgb(0 0 0 / 73%) 0px 16px 10px -10px;

    cursor: pointer;
    overflow: hidden;
    position: relative;
    transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    border: 3px solid rgba(255, 255, 255, 0.1);
  }
  
  
.slick-prev {
  z-index: 995;
  left: -65px;
}
.slick-prev:before, .slick-next:before {
  font-size: 45px;
}
.slick-next {
  z-index: 995;
  right: -45px;
}

.card_img_card { 
    inset: 0px;
    display: block;
    height: 100%;
    min-height: 18rem;
    object-fit: cover;
    opacity: 1;


    width: 100%;
    z-index: 1;
    top: 0; max-height: 12rem; }
    
    .card_img_card:hover {  box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px rgb(0 0 0 / 73%) 0px 16px 10px -10px;
        transform: scale(1.10);
        border-color: rgba(249,249,249,0.8); }
        .icons_div_card { display: flex; align-items: center; }
        .icons_div_card img{ max-height: 2.5rem; margin: 0 0.1rem; border: 5px solid #010101; border-radius: 50%; background: #010101; }
      
       .main_card .github_preview { position: absolute; max-height: 3rem; top: 2%; left: 10%; border: 5px solid #fff; border-radius: 50%; background: #fff; transition: 0.5s ease-in all; cursor: pointer; }
       .main_card .github_preview:hover { 
        border: 5px solid transparent;
        border-radius: 500px;
       
        background-clip: padding-box;
        padding: 5px;
        box-shadow: 0 3px 9px black, inset 0 0 9px white; }
       .main_card .live_preview { position: absolute; max-height: 3rem; top: 2%; right: 10%; border: 5px solid #fff; border-radius: 50%; background: #fff; transition: 0.5s ease-in all; cursor: pointer;  }
       .main_card .live_preview:hover { border: 5px solid transparent;
        border-radius: 500px;
       
        background-clip: padding-box;
        padding: 5px;
        box-shadow: 0 3px 9px black, inset 0 0 9px white; }

        .icons_build { border: 2px solid #e1e1e1 !important; background: #fff !important; transition: 0.5s ease-in all;  }
        .icons_build:hover { border: 5px solid transparent;
          border-radius: 500px;
         
          background-clip: padding-box;
          padding: 5px;
          box-shadow: 0 3px 9px black, inset 0 0 9px white; }

          .popup_project_single { position: relative; width: 65%; height: 75%; z-index: 9999 !important; position: fixed !important; top: 50%; left: 50%; transform: translate(-50% , -50%);   padding: 1.5rem 3.5rem;  z-index: 12; background: linear-gradient( #7e0b33e2  , #3d1b1bd5  ) bottom/100%  no-repeat; overflow-y: scroll; }

          .popup_project_single .project_single_card div img {     height: 8rem; }

          .popup_project_single .MuiSvgIcon-root { position: absolute; top: 8%; right: 3%; color: white; width: 35px; height: 35px; cursor: pointer;  }

          @media (max-width: 700px) {
            .popup_project_single { padding: 0.8rem 0.3rem; }
            .popup_project_single .project_single_card div img { height: 5rem; }
            .popup_project_single { width: 75%; }
            .main_card .github_preview { max-height: 2rem; }
            .main_card .live_preview  { max-height: 2rem !important; }
            .icons_div_card img { max-height: 2.1rem; }
          }

          
          @media (max-width: 450px) {
            .popup_project_single { padding: 0.2rem 0rem; }
            .icons_div_card img { max-height: 1.8rem; }
            .card_img_card { min-height: 10rem; }
          }